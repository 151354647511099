<template>
  <section>
    <TokenList class="container"/>
  </section>
</template>
<script>
import TokenList from "@/components/TokenList";
export default {
  components: { TokenList },
};
</script>
